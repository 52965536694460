<template>
  <MyDataTable
    :title="$route.meta.title + ' ها'"
    :subtitle="'مدیریت ' + $route.meta.title + ' ها'"
    :addtitle="'ایجاد ' + $route.meta.title + ' جدید'"
    :addpageRoute="
      `${$route.path}/create${
        $route.query.page ? '?page=' + $route.query.page : ''
      }`
    "
    :headers="headers"
    :items="items"
    :loading="loading"
    :pagesLength="pagesLength"
    @onPageChanged="GetList"
    @onDeleteClick="deleteItem"
    @onEditClick="onEditClick"
    class="mt-n10 moholand-shadow"
  >
    <template #middleButtonTag="{ row }">
      <v-tooltip top color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            :disabled="row.status != 1"
            @click="onDetailsClick(row)"
          >
            <v-icon small dark>mdi-apps</v-icon>
            مشاهده
          </v-btn>
        </template>
        <span dir="ltr" v-if="row.status == 1">
          {{ "moholand.com/scenarios/" + row.slug }}
        </span>
      </v-tooltip>
    </template>
    <template #contentTag="{ row }">
      <td>{{ row.title }}</td>
      <td>{{ new Intl.NumberFormat().format(row.price) }} تومان</td>
      <td v-if="row.user">
        {{ row.user.first_name || "" }} {{ row.user.last_name || "" }}
      </td>
      <td v-else>-</td>
      <td>
        <v-chip v-if="row.has_bought" x-small color="success">
          خریداری شده
        </v-chip>
        <v-chip v-else x-small color="primary">
          خریداری نشده
        </v-chip>
      </td>
      <td>
        <v-chip v-if="row.status == 0" color="yellow darken-3" dark x-small>
          در حال بررسی
        </v-chip>
        <v-chip v-else-if="row.status == 1" color="success" dark x-small>
          تایید شده
        </v-chip>
        <v-tooltip v-else top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="red"
              dark
              class="rounded"
              x-small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small class="ml-2">
                mdi-information-outline
              </v-icon>
              رد شده
            </v-chip>
          </template>
          <span>{{ row.status_desc }}</span>
        </v-tooltip>
      </td>
    </template>
  </MyDataTable>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDataTable from "@/components/MyDataTable";

export default {
  created() {
    this.GetList();
    console.log(this.destinationRoute);
  },
  components: {
    MyDataTable,
  },
  methods: {
    GetList: function() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/" + this.$route.meta.api + "/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: "user",
        },
      })
        .then((response) => {
          this.loading = false;
          this.pagesLength = response.data.last_page;
          this.path = response.data.path;
          this.items = response.data.data.map((val) => ({
            ...val,
            desc: "",
            authorName: val.author
              ? val.author.first_name + " " + val.author.last_name
              : "",
          }));
        })
        .catch((error) => {
          this.loading = false;
          console.log("items", error.response);
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/managescenarios/edit/" + item.slug,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      if (item.status != 1) {
        this.$root.$emit("toast", {
          text: "این داستان قابل نمایش نیست.",
        });
        return;
      }
      window.open("/scenarios/" + item.slug, "_blank");
    },
    onDeleteClick(item) {
      this.$refs.itemsDialog.show(
        "ایا مایل به حذف '" + item.title + "' هستید؟",
        item
      );
    },
    deleteItem(item) {
      if (!this.loading) this.loading = true;
      MyAxios.delete(`/${this.$route.meta.api}/${item.slug}/delete`)
        .then((response) => {
          this.$root.$emit("toast", {
            text: item.title + "با موفقیت حذف شد ",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: "مشکلی پیش آمد. لطفا دوباره تلاش کنید.",
          });
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      destinationRoute: this.$route.fullPath,
      loading: true,
      items: [],
      page: 1,
      path: null,
      pathParams: {},
      pagesLength: 1,
      headers: [
        {
          text: "عنوان",
          align: "start",
          value: "name",
        },
        {
          text: "قیمت",
          align: "start",
          value: "slug",
        },
        {
          text: "توسط",
          align: "start",
          value: "slug",
        },
        {
          text: "وضعیت خرید",
          align: "start",
          value: "slug",
        },
        {
          text: "وضعیت",
          align: "start",
          value: "slug",
        },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
